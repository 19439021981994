'use strict'

import 'cookieconsent/build/cookieconsent.min.js'

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

function checkStickyHeader () {
  var element = document.querySelector('.header')
  if (!element) return;
  if (window.scrollY === 0 || (document.documentElement.scrollTop === 0 && document.documentMode && document.documentMode < 12)) {      
    element.classList.remove('is-fixed')
  } else {
    element.classList.add('is-fixed')
  }  
}

function initStickyHeader () {
  checkStickyHeader()
  window.addEventListener('scroll', checkStickyHeader)
}

function initCookiesAlert () {
  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#e3e3e3",
        "text": "#666666"
      },
      "button": {
        "background": "#6699ff",
        "text": "#ffffff"
      }
    },
    "showLink": false,
    "theme": "classic"
  })
}

function showIEAlert () {
  var alert = document.getElementById('ie-alert-js')
  if (document.documentMode && document.documentMode < 12 && alert) {
    var body = document.querySelector('body')
    var header = document.querySelector('.header')
    if (document.documentMode > 9) {
      body.classList.add('ie-alert-visible')
      header.classList.add('ie-alert-visible')
    } else {
      body.classList += ' ie-alert-visible'
      header.classList += ' ie-alert-visible'
    }
    alert.style.display = 'block'
  }
}

// check IE
ready(showIEAlert)

// init sticky header
ready(initStickyHeader)

// init cookies alert
ready(initCookiesAlert)

// open chatbot popup
// ready(showWidget)

// init slider
// uncomment when slider needs to be on title page
// ready(initSlider)

// NOTE: disables the right click behaviour on the website as requested in https://trello.com/c/tuzYTJmJ/18-disable-right-click-on-the-website
document.addEventListener('contextmenu', event => event.preventDefault())
