import tippy from 'tippy.js';

const config = {
  // theme: 'light',
  placement: 'right',
  maxWidth: 253,
}

// Fix n Flip
tippy('#purchase-ltc-info', {
  ...config,
  content: 'The percentage of the property’s total purchase cost that a lender will finance',
});
tippy('#rehab-ltc-info', {
  ...config,
  content: 'The percentage of the property’s total renovation and construction costs that a lender will finance',
});
tippy('#total-ltc-info', {
  ...config,
  content: 'The percentage of the entire project cost, including purchase, renovation, and other expenses, that a lender will finance',
});
tippy('#ltv-arv-info', {
  ...config,
  content: '(Loan-to-After-Repair-Value) is the loan amount compared to the property\'s value after the project is completed',
});

// Bridge
tippy('#refinance-ltv-info', {
  ...config,
  content: 'The ratio of the loan amount to the current appraised value of the property',
});
tippy('#cash-out-refinance-ltv-info', {
  ...config,
  content: 'The ratio of the loan amount (including any cash taken out) to the current appraised value of the property, used to determine how much equity can be accessed in a refinance',
});

// Ground-up
tippy('#construction-ltc-info', {
  ...config,
  content: 'The percentage of the property’s total renovation and construction costs that a lender will finance',
});
tippy('#ltv-arv-info', {
  ...config,
  content: '(Loan-to-After-Repair-Value) is the loan amount compared to the property\'s value after the project is completed',
});

// Rental
tippy('#rental-ltv-info', {
  ...config,
  content: '(Loan-to-Value) is the loan amount compared to the property\'s value',
});
tippy('#min-dscr-info', {
  ...config,
  content: 'DSCR (Debt-Service-Coverage-ratio) is a ratio between the cash flow a property generates and its expenses',
});

// Multifamily
tippy('#rental-ltv-info', {
  ...config,
  content: '(Loan-to-Value) is the loan amount compared to the property\'s value',
});
