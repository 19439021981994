'use strict'

import 'cleave.js/dist/cleave.min.js'
import 'cleave.js/dist/addons/cleave-phone.us.js'

function onLoansFormSubmit (token, formNum) {
  if (!token || !formNum) return
  let form = document.getElementById('contact-form-' + formNum)
  let data = new FormData(form)
  data = data.entries()
  let obj = data.next()
  const retrieved = {}
  while (undefined !== obj.value) {    
    retrieved[obj.value[0]] = obj.value[1]
    obj = data.next()
  }

  form.querySelector('.acc-loans__form__success--js').style.display = 'none'
  form.querySelector('.acc-loans__form__error--js').style.display = 'none'

  let warnings = form.querySelectorAll('.form-error')
  Array.prototype.forEach.call(warnings, function (el, i) {
    el.style.display = 'none'
  })

  let fields = form.querySelectorAll('input, textarea')
  Array.prototype.forEach.call(fields, function (el, i) {
    el.classList.remove('is-danger')
  })

  if (retrieved.name && retrieved.phone && retrieved.email && retrieved.state && token) {
    let XHR = new XMLHttpRequest()

    retrieved.captcha = retrieved['g-recaptcha-response']
    delete retrieved['g-recaptcha-response']

    XHR.addEventListener('load', function (event) {
      if (event.target.status === 200) {
        form.querySelector('.acc-loans__form__fields--js').style.display = 'none'
        form.querySelector('.acc-loans__form__success--js').style.display = 'block'
        form.reset()
        if (window.grecaptcha) {
          grecaptcha.reset()
        }
      } else {
        // document.getElementById('contact-form-error').style.display = 'block'
      }
    })

    XHR.addEventListener('error', function (event) {
      form.querySelector('.acc-loans__form__error--js').style.display = 'block'
    })

    XHR.open('POST', process.env.API_URL + '/api/marketing/contact')
    XHR.setRequestHeader('Content-Type', 'application/json')
    XHR.setRequestHeader('X-Accolend-Env', process.env.NODE_ENV)
    XHR.send(JSON.stringify(retrieved))
  } else {
    if (!retrieved.name) {
      form.querySelector('input[name="name"]').classList.add('is-danger')
      form.querySelector('.acc-loans__form__error-name').style.display = 'block'
    }
    if (!retrieved.phone) {
      form.querySelector('input[name="phone"]').classList.add('is-danger')
      form.querySelector('.acc-loans__form__error-phone').style.display = 'block'
    }
    if (!retrieved.email) {
      form.querySelector('input[name="email"]').classList.add('is-danger')
      form.querySelector('.acc-loans__form__error-email').style.display = 'block'
    }
    if (!retrieved.state) {
      form.querySelector('input[name="state"]').classList.add('is-danger')
      form.querySelector('.acc-loans__form__error-state').style.display = 'block'
    }
  }
}

window.onLoansContactSubmit = onLoansFormSubmit
