'use strict'

function onNewsletterFormSubmit (token) {
  let form = document.getElementById('newsletter-form')
  let data = new FormData(form) // create new FormData object
  data = data.entries()
  let obj = data.next() // access iterator
  const retrieved = {}
  while (undefined !== obj.value) {
    retrieved[obj.value[0]] = obj.value[1]
    obj = data.next()
  } // parse FormData into simple object

  document.getElementById('newsletter-form-success').style.display = 'none'
  document.getElementById('newsletter-form-error').style.display = 'none'

  let warnings = document.querySelectorAll('#newsletter-form .form-error')
  Array.prototype.forEach.call(warnings, function (el, i) {
    el.style.display = 'none'
  })

  let fields = document.querySelectorAll('#newsletter-form input, #newsletter-form textarea')
  Array.prototype.forEach.call(fields, function (el, i) {
    el.classList.remove('is-danger')
  })

  if (retrieved.email && token) {
    let XHR = new XMLHttpRequest()

    retrieved.captcha = retrieved['g-recaptcha-response']
    delete retrieved['g-recaptcha-response']

    XHR.addEventListener('load', function (event) {
      if (event.target.status === 200 || event.target.status === 204) {
        document.getElementById('newsletter-form').style.display = 'none'
        document.getElementById('newsletter-form-success').style.display = 'block'
        document.getElementById('newsletter-form').reset()
        if (window.grecaptcha) {
          grecaptcha.reset()
        }
      } else {
        document.getElementById('newsletter-form').style.display = 'none'
        document.getElementById('newsletter-form-error').style.display = 'block'
      }
    })

    XHR.addEventListener('error', function (event) {
      document.getElementById('newsletter-form-error').style.display = 'block'
    })

    XHR.open('POST', process.env.API_URL + '/api/public/stuff/newsletter-signup')
    XHR.setRequestHeader('Content-Type', 'application/json')
    XHR.setRequestHeader('X-Accolend-Env', process.env.NODE_ENV)
    XHR.send(JSON.stringify(retrieved))
  } else {
    if (!retrieved.email) {
      document.querySelector('#newsletter-form input[name="email"]').classList.add('is-danger')
      document.getElementById('newsletter-form-error-email').style.display = 'block'
    }
  }
}

window.onNewsletterFormSubmit = onNewsletterFormSubmit
